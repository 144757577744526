/* This File is for {purpose} */

<template>
  <v-layout wrap justify-start pa-2 pa-sm-8>
    <v-flex xs12>
      <v-layout wrap justify-start pb-4>
        <v-flex xs6 sm3 md2 xl2 pr-2 pb-md-0>
          <AppDatePicker
            v-model="filter.month"
            :min="minDate"
            :max="maxDate"
            type="month"
            :placeholder="$t('pages.schedules.select_month')"
            hideDetails
            @submit="prepareFetchData(false)"
          />
        </v-flex>
        <v-spacer />
        <v-flex xs12 sm7 md5 pa-sm-0 align-self-center>
          <v-layout wrap justify-end fill-height>
            <v-flex xs6 sm4 md4 pr-2>
              <v-select
                v-model="filter.qualification"
                :placeholder="$t('form.qualification')"
                :items="qualifications"
                item-value="id"
                item-text="name"
                outlined
                dense
                hide-details
                clearable
                @change="prepareFetchData(false)"
              ></v-select>
            </v-flex>
            <v-flex xs6 sm4 md4>
              <v-text-field
                v-model="filter.searchKey"
                :placeholder="$t('pages.schedules.search_nurse')"
                type="search"
                outlined
                dense
                hide-details
                append-icon="mdi-magnify"
                clearable
                @change="prepareFetchData(false)"
              />
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex xs12 align-self-start>
      <CalendarTable
        :items="employeeSchedules"
        :start="startDate"
        :end="endDate"
      />
      <div class="py-4">
        <v-pagination
          v-model="pagination.page"
          total-visible="6"
          :length="pagination.last_page"
        />
      </div>
    </v-flex>
  </v-layout>
</template>

<script>
import CalendarTable from "@/components/CalendarTable";
import { mapActions, mapGetters } from "vuex";
import AppDatePicker from "@/components/AppDatePicker.vue";
import Helpers from "@/libs/helper/components/helper-functions";

export default {
  name: "Schedules",
  components: {
    CalendarTable,
    AppDatePicker,
  },
  data: () => ({
    filter: {
      month: null,
      qualification: null,
      searchKey: null,
    },
    pagination: {
      per_page: 25,
      page: 1,
      last_page: 1,
      total: 0,
      from: 0,
      to: 0,
    },
  }),
  computed: {
    ...mapGetters({
      qualifications: "app/getQualifications",
      employeeSchedules: "hospital/getEmployeeSchedules",
    }),
    minDate() {
      // return this.$moment().format("YYYY-MM");
      return "2023-01";
    },
    maxDate() {
      return this.$moment().add(24, "months").format("YYYY-MM");
    },
    startDate() {
      return this.$moment(this.filter.month, "YYYY-MM")
        .startOf("month")
        .format("YYYY-MM-DD");
    },
    endDate() {
      return this.$moment(this.filter.month, "YYYY-MM")
        .endOf("month")
        .format("YYYY-MM-DD");
    },
  },
  mounted() {
    const breadCrumbs = [
      {
        name: this.$route.name,
        text: "pages.schedules.title",
      },
    ];
    this.$store.commit("app/setBreadCrumbs", breadCrumbs);
    this.getData();
  },
  created() {
    this.filter.month =
      this.$route.query.date || this.$moment().format("YYYY-MM");
    this.filter.qualification =
      parseInt(this.$route.query.qualification) || null;
    this.filter.searchKey = this.$route.query.searchKey || null;
    this.pagination.page = parseInt(this.$route.query.page) || 1;
  },
  watch: {
    "pagination.page"() {
      this.updateQueryParams();
      this.getData();
    },
  },
  methods: {
    ...mapActions({
      getEmployeeSchedules: "hospital/getEmployeeSchedules",
    }),
    prepareFetchData(paginate = false) {
      if (!paginate) this.pagination.page = 1;

      this.updateQueryParams();
      this.getData();
    },
    updateQueryParams() {
      this.$router.replace({
        query: {
          date: this.filter.month,
          qualification: this.filter.qualification,
          searchKey: this.filter.searchKey,
          page: this.pagination.page,
        },
      });
    },
    getData() {
      const payload = {
        ...this.pagination,
        is_active: true,
        from: this.startDate,
        to: this.endDate,
        qualification_id: this.filter.qualification,
        search: this.filter.searchKey,
      };
      this.getEmployeeSchedules(payload).then((res) => {
        this.pagination = Helpers.setPagination(res);
      });
    },
  },
};
</script>

<style scoped lang="scss">
.v-btn-toggle {
  border-radius: 7px;
}
.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
  border-color: #c0cbe0 !important;
}
.v-btn--active::before,
.v-btn--active:hover::before,
.v-btn--active {
  opacity: 1 !important;
  background-color: #dce8ff;
}
.v-btn-toggle > .v-btn.v-btn {
  opacity: 1;
}
.v-btn:focus::before {
  opacity: 0;
}
.v-btn:hover::before {
  background-color: #dce8ff;
  opacity: 0.34;
}
</style>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: #c0cbe0 !important;
}
.v-text-field--outlined::v-deep input::placeholder,
.v-text-field--outlined::v-deep label,
.v-text-field--outlined::v-deep input {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: #9fadc8;
}
</style>
