var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-simple-table", {
    staticClass: "schedule-table",
    attrs: { id: "calendar_table", "fixed-header": "", height: "100%" },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function () {
          return [
            _c("thead", [
              _c(
                "tr",
                [
                  _c("th", { staticClass: "text-left w-2 sticky" }, [
                    _vm._v(" "),
                  ]),
                  _c("th", { staticClass: "text-left sticky name-col" }, [
                    _vm._v(_vm._s(_vm.$t("form.name"))),
                  ]),
                  _vm._l(_vm.weekDays, function (week, ind) {
                    return [
                      _c(
                        "th",
                        { key: ind, staticClass: "text-left w-2 week-col" },
                        [
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("pages.schedules.week")) +
                                " " +
                                _vm._s(week.weekNumber) +
                                " "
                            ),
                          ]),
                        ]
                      ),
                      _vm._l(week.days, function (day) {
                        return _c(
                          "th",
                          { key: day.day_slug, staticClass: "text-center" },
                          [
                            _c(
                              "p",
                              {
                                staticClass: "ma-0",
                                class: [
                                  _vm.weekEnd(day)
                                    ? "weekend-day-label"
                                    : "weekday-label",
                                ],
                              },
                              [_vm._v(" " + _vm._s(day.day_label) + " ")]
                            ),
                            _c(
                              "p",
                              {
                                staticClass: "ma-0",
                                class: [
                                  _vm.weekEnd(day)
                                    ? "weekend-day-number"
                                    : "weekday-number",
                                ],
                              },
                              [_vm._v(" " + _vm._s(day.day_number) + " ")]
                            ),
                          ]
                        )
                      }),
                    ]
                  }),
                ],
                2
              ),
            ]),
            _c(
              "tbody",
              _vm._l(_vm.items, function (item, index) {
                return _c(
                  "tr",
                  { key: item.id },
                  [
                    _c("th", { staticClass: "sticky" }, [
                      _vm._v(_vm._s(index + 1)),
                    ]),
                    _c(
                      "th",
                      {
                        staticClass: "sticky name-col",
                        style: {
                          minWidth: _vm.$vuetify.breakpoint.smAndUp
                            ? "300px"
                            : "150px",
                        },
                      },
                      [
                        _c(
                          "v-layout",
                          { attrs: { wrap: "", "justify-space-between": "" } },
                          [
                            _c(
                              "v-flex",
                              {
                                attrs: {
                                  xs12: "",
                                  sm6: "",
                                  "align-self-center": "",
                                },
                              },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticStyle: { color: "black" },
                                    attrs: {
                                      to: {
                                        name: "employee",
                                        params: { id: item.id },
                                      },
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(item.name) + " ")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              { attrs: { xs3: "", sm2: "", "px-1": "" } },
                              [
                                _c(
                                  "v-layout",
                                  {
                                    attrs: {
                                      wrap: "",
                                      "justify-start": "",
                                      "text-center": "",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "subtitle-2 text",
                                        attrs: {
                                          xs12: "",
                                          "align-self-center": "",
                                          "text-center": "",
                                        },
                                      },
                                      [_vm._v(" " + _vm._s(item.present) + " ")]
                                    ),
                                    _c(
                                      "v-flex",
                                      {
                                        attrs: {
                                          xs12: "",
                                          "align-self-center": "",
                                          "text-center": "",
                                        },
                                      },
                                      [
                                        _c("v-avatar", {
                                          attrs: {
                                            color: "success",
                                            size: "9",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              { attrs: { xs3: "", sm2: "", "px-1": "" } },
                              [
                                _c(
                                  "v-layout",
                                  { attrs: { wrap: "", "justify-start": "" } },
                                  [
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "subtitle-2 text",
                                        attrs: {
                                          xs12: "",
                                          "align-self-center": "",
                                          "text-center": "",
                                        },
                                      },
                                      [_vm._v(" " + _vm._s(item.absent) + " ")]
                                    ),
                                    _c(
                                      "v-flex",
                                      {
                                        attrs: {
                                          xs12: "",
                                          "align-self-center": "",
                                          "text-center": "",
                                        },
                                      },
                                      [
                                        _c("v-avatar", {
                                          attrs: { color: "error", size: "9" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              { attrs: { xs3: "", sm2: "", "px-1": "" } },
                              [
                                _c(
                                  "v-layout",
                                  { attrs: { wrap: "", "justify-start": "" } },
                                  [
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "subtitle-2 text",
                                        attrs: {
                                          xs12: "",
                                          "align-self-center": "",
                                          "text-center": "",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(item.assigned) + " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "v-flex",
                                      {
                                        attrs: {
                                          xs12: "",
                                          "align-self-center": "",
                                          "text-center": "",
                                        },
                                      },
                                      [
                                        _c("v-avatar", {
                                          attrs: {
                                            color: "#3278ff",
                                            size: "9",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._l(_vm.weekDays, function (week, ind) {
                      return [
                        _c("td", {
                          key: ind,
                          staticClass: "text-left w-2 no-border",
                        }),
                        _vm._l(week.days, function (day) {
                          return _c(
                            "td",
                            {
                              key: day.day_slug,
                              staticClass: "text-center pa-0 no-border",
                            },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass:
                                    "schedule-card ml-1 mt-1 py-1 px-0",
                                  class: {
                                    "mb-1": index === _vm.items.length - 1,
                                  },
                                  attrs: {
                                    color: _vm.weekEnd(day) ? "#ff520045" : "",
                                    id: item.id + "_" + day.date,
                                  },
                                },
                                _vm._l(
                                  _vm.filterShift(item.schedules[day.date]),
                                  function (schedule, ind) {
                                    return _c(
                                      "span",
                                      {
                                        key: ind,
                                        staticClass:
                                          "py-1 px-2 mb-1 rounded-xl d-inline-block",
                                        class: {
                                          "mr-1": ind % 2 === 0,
                                          available: schedule.is_available,
                                          "un-available":
                                            !schedule.is_available,
                                          accepted: schedule.is_accepted,
                                          disabled: schedule.is_disabled,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.getShiftName(
                                                schedule.shift_id
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ],
                            1
                          )
                        }),
                      ]
                    }),
                  ],
                  2
                )
              }),
              0
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }