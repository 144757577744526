/* * @File CalendarTable.vue * @Description This File is for calendar table *
@Created on (22 Nov 2022 at 10:46 pm) */
<template>
  <v-simple-table
    class="schedule-table"
    id="calendar_table"
    fixed-header
    height="100%"
  >
    <template #default>
      <thead>
        <tr>
          <th class="text-left w-2 sticky">&numsp;</th>
          <th class="text-left sticky name-col">{{ $t("form.name") }}</th>
          <template v-for="(week, ind) in weekDays">
            <th class="text-left w-2 week-col" :key="ind">
              <span>
                {{ $t("pages.schedules.week") }} {{ week.weekNumber }}
              </span>
            </th>
            <th
              class="text-center"
              v-for="day in week.days"
              :key="day.day_slug"
            >
              <p
                class="ma-0"
                :class="[weekEnd(day) ? 'weekend-day-label' : 'weekday-label']"
              >
                <!--                {{ $t("calendar." + day.day_label) }}-->
                {{ day.day_label }}
              </p>
              <p
                class="ma-0"
                :class="[
                  weekEnd(day) ? 'weekend-day-number' : 'weekday-number',
                ]"
              >
                {{ day.day_number }}
              </p>
            </th>
          </template>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="item.id">
          <th class="sticky">{{ index + 1 }}</th>
          <th
            class="sticky name-col"
            :style="{
              minWidth: $vuetify.breakpoint.smAndUp ? '300px' : '150px',
            }"
          >
            <v-layout wrap justify-space-between>
              <v-flex xs12 sm6 align-self-center>
                <router-link
                  :to="{ name: 'employee', params: { id: item.id } }"
                  style="color: black"
                >
                  {{ item.name }}
                </router-link>
              </v-flex>
              <v-flex xs3 sm2 px-1>
                <v-layout wrap justify-start text-center>
                  <v-flex
                    xs12
                    align-self-center
                    text-center
                    class="subtitle-2 text"
                  >
                    {{ item.present }}
                  </v-flex>
                  <v-flex xs12 align-self-center text-center>
                    <v-avatar color="success" size="9"></v-avatar>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs3 sm2 px-1>
                <v-layout wrap justify-start>
                  <v-flex
                    xs12
                    align-self-center
                    text-center
                    class="subtitle-2 text"
                  >
                    {{ item.absent }}
                  </v-flex>
                  <v-flex xs12 align-self-center text-center>
                    <v-avatar color="error" size="9"></v-avatar>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs3 sm2 px-1>
                <v-layout wrap justify-start>
                  <v-flex
                    xs12
                    align-self-center
                    text-center
                    class="subtitle-2 text"
                  >
                    {{ item.assigned }}
                  </v-flex>
                  <v-flex xs12 align-self-center text-center>
                    <v-avatar color="#3278ff" size="9"></v-avatar>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </th>
          <template v-for="(week, ind) in weekDays">
            <td class="text-left w-2 no-border" :key="ind"></td>
            <td
              class="text-center pa-0 no-border"
              v-for="day in week.days"
              :key="day.day_slug"
            >
              <v-card
                class="schedule-card ml-1 mt-1 py-1 px-0"
                :class="{ 'mb-1': index === items.length - 1 }"
                :color="weekEnd(day) ? '#ff520045' : ''"
                :id="item.id + '_' + day.date"
              >
                <span
                  v-for="(schedule, ind) in filterShift(
                    item.schedules[day.date]
                  )"
                  :key="ind"
                  class="py-1 px-2 mb-1 rounded-xl d-inline-block"
                  :class="{
                    'mr-1': ind % 2 === 0,
                    available: schedule.is_available,
                    'un-available': !schedule.is_available,
                    accepted: schedule.is_accepted,
                    disabled: schedule.is_disabled,
                  }"
                >
                  {{ getShiftName(schedule.shift_id) }}
                </span>
              </v-card>
            </td>
          </template>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import { _SHIFTS } from "@/constants/shifts";

export default {
  name: "CalendarTable",
  props: {
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
    start: {
      type: String,
      required: true,
    },
    end: {
      type: String,
      required: true,
    },
  },
  computed: {
    weekDays() {
      let firstDay = this.$moment(this.start, "YYYY-MM-DD").startOf("month");
      let endDay = this.$moment(this.end, "YYYY-MM-DD").endOf("month");
      let calendar = [];
      let weekNumber = firstDay.get("week"); // Fix for week no issue
      // let weekNumber = 1;
      let weekDays = {
        weekNumber: weekNumber,
        days: [],
      };
      for (let day = firstDay; day.isSameOrBefore(endDay); day.add(1, "day")) {
        let dayLabel = day.format("ddd");
        let dayNumber = day.format("D");
        let date = day.format("YYYY-MM-DD");
        if (weekNumber === weekDays.weekNumber) {
          weekDays.days.push({
            day_slug: `${dayLabel.toLowerCase()}_${dayNumber}`,
            day_label: dayLabel,
            day_number: dayNumber,
            date: date,
          });
        }
        if (day.day() === 0) {
          calendar.push(weekDays);
          weekNumber = day.get("week") + 1; // Fix for week no issue
          // weekNumber++;
          weekDays = {
            weekNumber: weekNumber,
            days: [],
          };
        }
      }
      if (weekDays.days.length) {
        calendar.push(weekDays);
      }
      return calendar;
    },
  },
  watch: {
    items: {
      handler() {
        setTimeout(() => {
          this.scrollToToday();
        }, 200);
      },
      deep: true,
    },
  },
  methods: {
    /*
     * Weekend finding
     * @param day
     * @returns {boolean}
     */
    weekEnd(day) {
      return (
        day.day_label.includes("Sun") ||
        day.day_label.includes("Sat") ||
        day.day_label.includes("Sa.") ||
        day.day_label.includes("So.")
      );
    },
    getShiftName(shift) {
      return _SHIFTS[shift];
    },

    filterShift(itm) {
      const shiftMaster = [];
      const shiftIDs = [1, 2, 3, 4];
      for (const shift_id of shiftIDs) {
        const shift = itm?.find((s) => s.shift_id === shift_id);
        if (shift) {
          shiftMaster.push(shift);
        } else {
          shiftMaster.push({
            is_disabled: true,
            shift_id,
          });
        }
      }
      return shiftMaster;
    },
    scrollToToday() {
      const today = this.$moment().format("YYYY-MM-DD");
      const scrollableElement = document.getElementsByClassName(
        "v-data-table__wrapper"
      )[0];
      let element = document.getElementById(this.items[0]?.id + "_" + today);
      let position = element?.offsetLeft - 400;
      scrollableElement.scrollTo({
        left: position,
        behavior: "auto",
      });
    },
  },
};
</script>

<style lang="scss"></style>
